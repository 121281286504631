#root {
  padding: 16px;
}

.mobile-friendly {
  font-size: 16px !important;
}

.min-component-height {
  min-height: 74vh;
}

.top-padding {
  padding-top: 5rem;
}

.remove-borders {
  border-radius: 0 !important;
}

.step-progress {
  display: flex;
  justify-content: space-between;
}

.two-inline-buttons {
  display: table;
  margin: 0 auto;
}

.component-height {
  min-height: 80vh;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.two-inline-buttons button {
  margin-left: 25px;
  margin-right: 25px;
}

.space-btn {
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 960px) {
  .three-inline-buttons button {
    width: 100%;
    margin: 20px;
    text-align: center;
  }
}

.steps-content {
  margin-top: 16px;
  border-radius: 6px;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}

.steps-div {
  padding: 50px;
  width: 600px;
  margin: auto;
}
.steps-title {
  color: #ffffff;
}
.overall-color {
  color: "#0D212D";
}

.label {
  font-size: 16px;
}

.card-min-height {
  min-height: 12rem;
}

.home-background {
  background-image: url("../images/Background.jpg");
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.reward-font {
  font-size: 20px !important;
  font-style: "counter";
}

.btn-outline-danger {
  border-color: #ff4961;
  background-color: #ffffff;
  color: #ff4961;
  height: 54px;
  font-size: 20px;
  padding: 0 28px;
  border-radius: 1.5rem;
}

.alert {
  min-width: 150px;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 3px;
  @extend .shadow-1;
  &:hover {
    @extend .shadow-2;
  }
  &-success {
    background-color: lighten(#5cb85c, 15%);
    border-color: lighten(#5cb85c, 10%);
    color: darken(#5cb85c, 15%);
  }
  &-warning {
    background-color: lighten(#e2a41f, 15%);
    border-color: lighten(#e2a41f, 10%);
    color: darken(#e2a41f, 15%);
  }
  &-danger {
    background-color: lighten(#d43f3a, 15%);
    border-color: lighten(#d43f3a, 10%);
    color: darken(#d43f3a, 15%);
  }
  p {
    padding: 0;
    margin: 0;
  }
  i {
    padding-right: 5px;
    vertical-align: middle;
    font-size: 24px;
  }
  .close-alert {
    -webkit-appearance: none;
    position: relative;
    float: right;
    padding: 0;
    border: 0;
    cursor: pointer;
    color: inherit;
    background: 0 0;
    font-size: 21px;
    line-height: 1;
    font-weight: bold;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
    filter: alpha(opacity=40);
    opacity: 0.4;
    &:hover {
      filter: alpha(opacity=70);
      opacity: 0.7;
    }
  }
}

.close-alert {
  -webkit-appearance: none;
  position: relative;
  float: right;
  padding: 0;
  border: 0;
  cursor: pointer;
  color: inherit;
  background: 0 0;
  font-size: 21px;
  line-height: 1;
  font-weight: bold;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
  filter: alpha(opacity=40);
  opacity: 0.4;
  &:hover {
    filter: alpha(opacity=70);
    opacity: 0.7;
  }
}

.shadow-1 {
  box-shadow: 0 1px 3px rgba(#000, 0.12), 0 1px 2px rgba(#000, 0.24);
}
.shadow-2 {
  box-shadow: 0 3px 6px rgba(#000, 0.16), 0 3px 6px rgba(#000, 0.23);
}

.home-table .spacing-table {
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  border-collapse: separate;
  table-layout: fixed;
  border-spacing: 0 5px; /* this is the ultimate fix */
}
.home-table .spacing-table th {
  text-align: left;
  padding: 5px 15px;
}
.home-table .spacing-table td {
  border-width: 1px 0;
  width: 50%;
  border-color: white;
  border-style: solid;
  color: white;
  padding: 10px 15px;
  text-align: center;
}
.home-table .spacing-table td:first-child {
  border-left-width: 1px;
  border-radius: 10px 0 0 10px;
}
.home-table .spacing-table td:last-child {
  border-right-width: 1px;
  border-radius: 0 10px 10px 0;
}

.home-table .spacing-table thead {
  display: table;
  table-layout: fixed;
  width: 100%;
  border: 0px;
}
.home-table .spacing-table thead th {
  text-align: center;
}
.home-table .spacing-table tbody {
  display: table;
  table-layout: fixed;
  width: 100%;
  border-spacing: 0 10px;
}

.icon-padding {
  padding: 1rem;
}

.md-stepper-horizontal {
  display: table;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 3px 8px -6px rgba(0, 0, 0, 0.5);
}
.md-stepper-horizontal .md-step {
  display: table-cell;
  position: relative;
  padding: 24px;
}
.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
  display: none;
}
.md-stepper-horizontal .md-step .md-step-circle {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  border: 1px solid #ffffff;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  font-size: 16px;
  font-weight: 600;
}
.md-stepper-horizontal .md-step.editable .md-step-circle:before {
  font-family: "FontAwesome";
  font-weight: 100;
  content: "\f040";
}
.md-stepper-horizontal .md-step .md-step-title {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 600;
}
.md-stepper-horizontal .md-step .md-step-title {
  font-weight: 600;
}
.md-stepper-horizontal .md-step .md-step-optional {
  font-size: 12px;
}

.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
  position: absolute;
  top: 50px;
  height: 1px;
  border-top: 1px dashed #ffffff;
}
.md-stepper-horizontal .md-step .md-step-bar-right {
  right: 0;
  left: 50%;
  margin-left: 25px;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
  left: 0;
  right: 50%;
  margin-right: 25px;
}

.md-step-title,
.md-step-optional {
  color: #ffffff;
}

.p-days-left {
  font-size: 75px;
}

.remove-border-outline {
  outline: none;
}

.text-justify {
  text-align: justify;
}

a:hover {
  color: #4dcf8e;
}

.space-font {
  line-height: 0.8cm;
  color:  #333333;
}


.card-bg-platinum {
  background: linear-gradient( #ffffff, #DDDDDE);
}

.card-bg-platinum .card-badge {
  font-weight: 400;
  font-size: 1.2rem;
  background-color:  #3b48ab;
  border-radius: 5px;
  float: left;
  padding: 0 12px;
  color: #ffffff !important;
}
.card-bg-platinum .card-buttons{
  background: linear-gradient(to right, rgb(51, 71, 182), #1F265A);
  border-radius: 20px
}

.card-bg-platinum .card-buttons:hover{
  color: #ffa726;
}

.card-bg-platinum .card-links {
   color: #1F265A !important;
}

.card-bg-platinum .card-links:hover {
  color: #ffa726 !important;
}
.card-bg-golden {
  background: linear-gradient( #FDE26C, #FFC733);
}

.card-bg-golden .card-badge {
  font-weight: 400;
  font-size: 1.2rem;
  background-color:  #ffffff;
  border-radius: 5px;
  float: left;
  padding: 0 12px;
  color: #000000 !important;
}

.card-bg-golden .card-buttons{
  background: linear-gradient(to right, #FEFEFE, #F5F5F5);
  border-radius: 20px;
  color: #000000 !important;
}

.card-bg-golden .card-buttons:hover{
  background: linear-gradient(to right, rgb(51, 71, 182), #1F265A);
  color: #FFC733 !important;
}

.card-bg-golden .card-links {
   color: #1F265A !important;
}

.card-bg-golden .card-links:hover {
  color: #FFC733 !important;
}


.card-bg-promethium {
  background: linear-gradient( #bff9a2, #1ee87c);
}

.card-bg-promethium .card-badge {
  font-weight: 400;
  font-size: 1.2rem;
  background-color:  #1ee87c;
  border-radius: 5px;
  float: left;
  padding: 0 12px;
  color: #ffffff !important;
}
.card-bg-promethium .card-buttons{
  background: linear-gradient(to right, rgb(51, 71, 182), #1F265A);
  border-radius: 20px
}

.card-bg-promethium .card-buttons:hover{
  color: #ffa726;
}

.card-bg-promethium .card-links {
  color: #1F265A !important;
}

.card-bg-promethium .card-links:hover {
  color: #ffa726 !important;
}

.inline-labels {
  font-size: 16px !important;
}

.card-bg-diamond {
  // background: linear-gradient( #20d7dd, #038da5);
}

.card-bg-diamond .card-buttons{
  background: linear-gradient(to right, #FEFEFE, #F5F5F5);
  border-radius: 20px;
  color: #000000 !important;
}

.card-bg-diamond .card-buttons:hover{
  background: linear-gradient(to right, rgb(51, 71, 182), #1F265A);
  color: #ffffff !important;
}

.card-bg-diamond .text-color {
  color: #ffffff;
}

.card-bg-palladium {
  background: linear-gradient( #82617e, #8a5b96);
  color: #ffffff;
}

.card-bg-palladium .black-text {
  color: #ffffff !important;
}

.card-bg-titanium {
  background: linear-gradient( #515151, #3f3f3f);
  color: #ffffff;
}

.card-bg-titanium .black-text {
  color: #ffffff !important;
}

.info-table tr {
  border: 1px solid #49498f;
  padding: 1rem;
  color: #c4c1c1;
}

.card-row .row {
  margin-bottom: 0px !important;
}

.stepper-buttons {
  border-radius: 20px;
  color: #ffffff !important;
  background: linear-gradient(to left, rgb(33, 47, 233), #000e77);
  border: 1px solid #ffffff;
}

.stepper-buttons:hover {
  color: #FFC733 !important;
}

.cancel-buttons {
  border-radius: 20px;
  color: #ffffff !important;
  background: linear-gradient(to left, #c62828, #e53935);
  border: 1px solid #ffffff;
}

.cancel-buttons:hover {
  color: #000000 !important;
  background: linear-gradient(to left, #c62828, #e53935);
}

.stepper-badge {
  background: linear-gradient(to right, #FEFEFE, #F5F5F5);
  border-radius: 5px;
  padding: 5px ;
  border: 1px solid #ffffff;
  color: #000000 !important;
  font-weight: 500;
}

.review-title {
  font-size: 20px
}

.default-bg{
  background: linear-gradient(to right, rgb(51, 71, 182), #1F265A);
}

.default-bg-extended {
  background: linear-gradient(to left, rgb(51, 71, 182), #1F265A);
}

.footer {
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
}

.footer-copyright {
  padding: 16px;
}

.success-labels {
  padding: 1r3m;
}

.no-bottom-margin .row {
  margin-bottom: 0px;
}

.success-modal .modal-content { text-align: center; min-height: 400px !important;}


.address-box {
  border-radius: 40pt;
  background-color: #55554433;
}

.contract-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: white;
}

.contract-logo {
  width: 50px;
  height: 50px;
  border: none;
}

.contract-title .title {
  text-transform: uppercase;
  font-size: 40px;
  font-weight: 800;
}

.contract-title .subtitle {
  text-transform: uppercase;
  font-size: 18px;
}

.contract-title-logo {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contract-title-heading {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.box-label {
  text-transform: uppercase;
  font-size: 14px;
  padding-top: 14px;
  color: gray;
  font-weight: 800;
}

.box-value {
  text-transform: uppercase;
  font-size: 18px;
}

.box-value a {
  color: white;
}

.btn-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
}

.link-btn {
  border-width: 3px;
  border-color: #F22F54;
  border-radius: 30px;
  border-style: solid;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-btn span {
  font-size: 16px;
  text-transform: uppercase;
  color: white;
}

.gap {
  height: 30px;
}

.right-row {
  display: flex;
  flex-direction: row;
  height: 56px;
  justify-content: space-between;
  align-items: center;
}

.right-row {
  color: white;
  font-size: 18px;
}

.text-with-suffix {
  width: 250px;
  text-align: left;
}

.text-with-suffix-text {
  font: 18px;
  font-weight: 800;
  color: white;
}

.text-with-suffix-grey {
  font: 24px;
  color: grey;
}

.text-with-suffix-suffix {
  font: 24px;
  color: #F22F54;
}

.main-progress {
  background-color: #260618;
  border-radius: 15px;
  height: 40px;
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.main-progress-text-1 {
  width: 300px;
  padding-left: 30px;
}

.main-progress-text-2 {
  width: 100px;
  padding-left: 16px;
}